import axios from 'axios';
import {Utils} from '../utils/Utils';


export class documentosService {

    public obtenerDocumentos(libraryPath:string):Promise<any[]>{
        let token = Utils.obtenerCookie("token");
        return new Promise((resolve,reject)=>{  
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}investors/sharepoint/files?libraryPath=${libraryPath}`,
                        {headers: {Authorization: `Bearer ${token}`}
                    })
                        .then((data) => 
                        { 
                            if (data !== null) {
                                return resolve(data.data.result);
                            }
                            else {
                                return reject(data);
                            }
                        })
                        .catch(data=>{
                            let system = window.location.pathname;
                            window.location.href = `/login`+system;
                            return reject(data);
                        })
                })
            } 
            catch (error) {
                return reject(error);
            }
        });
    }

    public descargarDocumentos(relativeUrl:string,name:string):Promise<any>{
        let token = Utils.obtenerCookie("token");
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_inversores}investors/sharepoint/download?relativeUrl=${relativeUrl}&name=${name}`,
                    {headers: {Authorization: `Bearer ${token}`}
                })
                    .then((data:any) => 
                    { 
                        if (data.data.code == 200) {
                            return resolve(data.data.result);
                        }
                        else {
                            return reject(null)
                        }
                    })
                    //window.open(`${config.data[0].url_inversores}/investors/sharepoint/download?relativeUrl=${relativeUrl}&name=${name}`,'_blank');  
                    //return resolve(`${config.data.url_inversores}/investors/sharepoint/download?relativeUrl=${relativeUrl}&name=${name}`)                  
                });
            } catch (error) {
                return reject(error);
            }
        });
    }

    GetBlobStorageDocument(docUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`https://qualitaswebapi.azurewebsites.net/api/Masters/GetBlobDocument?url=${docUrl}​​​​​​​​&strContainerName=q-energy`)
                .then((data) => {
                    if (data !== null) {
                        return resolve(data.data);
                    }
                    else {
                        return reject(null);
                    }
                })
        });
    }

    public get(urlSite: string, libraryname: string) {
        return new Promise((resolve, reject) => {
            Utils.getConfig().then((config: any) => {
                axios.get(`${config.data[0].url_inversores}crmfiles/files?urlSite=${urlSite}&libraryname=${libraryname}`)
                    .then((data) => {
                        if (data !== null) {
                            return resolve(JSON.parse(data.data.result));
                        }
                        else {
                            return reject(null);
                        }
                    })
            });
        });
    }

}